<template>
  <div class="dao-hang ">
    <zi-xun-header />
<!--    <van-tabs v-model:active="page" @change="pageChange">-->
<!--      <van-tab title="平台介绍" name="PlatformPlatformProduce"></van-tab>-->
<!--      <van-tab title="专家库" name="PlatformSpecialistLibrary"></van-tab>-->
<!--      <van-tab title="公司新闻" name="PlatformCompanyNews"></van-tab>-->
<!--      <van-tab title="常见问题" name="PlatformGeneralProblem"></van-tab>-->
<!--    </van-tabs>-->
  </div>
</template>

<script>
import ZiXunHeader from "@/views/platform/module/ZiXunHeader";
export default {
  name: 'headerNav',
  components: {ZiXunHeader},
}
</script>

<style scoped>
.dao-hang{
  /*position: fixed;*/
  width: 100%;
  top: 50px;
}
</style>
