<template>
  <div class="bu-ju-3 bg-aaa">
    <div class="top">
      <nav-header title="平台介绍"></nav-header>
      <header-nav page-name="PlatformPlatformProduce" />
    </div>
    <div class="body bg-aaa">
      <div class="lie-biao max-width">
        <div v-if="Object.keys(dataInfo).length>0">
          <div v-html="dataInfo.post_content"></div>
        </div>
        <div v-else>
          <van-empty description="暂无数据"/>
        </div>
      </div>
<!--      <div class="page-content">


      </div>-->
    </div>
    <div class="">
      <footer-nav/>
    </div>
  </div>



</template>


<script>
  //服务大厅
  import NavHeader from '@/components/top/NavHeader'
  import FooterNav from "../open/footerNav";
  import http from "../../api/http";
  import HeaderNav from "@/views/platform/module/headerNav";

  export default {
    name: 'PlatformProduce',
    components: {
      HeaderNav,
      NavHeader,
      FooterNav
    },
    data() {
      return {
        dataInfo: {},
        pageName: 'PlatformPlatformProduce'
      }
    },
    mounted() {
      this.getLoad()
    },
    methods: {
      // 获取数据
      getLoad() {
        http.get('/gzhphp/fu_wu_shang/getPingTaiJieShao').then(res => {
          if (res.data) {
            this.dataInfo = res.data;
            console.log(this.dataInfo)
          }
        })
      },

    }
  }
</script>

<style scoped lang="less">
  //@import "../../assets/da-ting.less";

  .page-content .dao-hang {
    //height: auto;
  }
  .lie-biao{
    //margin-top: 15px;
  }
</style>
